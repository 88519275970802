import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';
import MenuAnchorArticles from '../../atoms/menu-anchor-articles/menu-anchor-articles.jsx';
import './article-generic.scss';

const ArticleGeneric = props => {
    let contTitles = 0
    const { params } = props;
    const { contenidoDelArticulo, primerosParrafos, menuAncla } = params;
    const locale = process.env.LOCALE || "es-CO"
    const datalayerLinks = (labelText) => {      
        const location = typeof window === 'undefined' ? '' : window.location.href
        pushDataLayerEvent({
          event: 'Interacciones_Enlaces',
          category: 'Links',
          action: 'click',
          label: labelText,
          location,
          user_data: [],
          log_status: '',
        })
    }
    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node, next) => {                
                if(node.data.target.fields){
                    const defaultSrc = node.data.target.fields?.file['es-CO'].url || ''
                    const src = node.data.target.fields?.file[locale] && node.data.target.fields?.file[locale].url || defaultSrc
                    const alt = node.data.target.fields?.title[locale] || node.data.target.fields?.title['es-CO'] || ''
                    
                    return (
                        <img 
                            className="f-aticle-content-img"
                            src={src}
                            alt={alt} 
                        />
                    )
                }else{
                    return null
                }
            },
            [INLINES.HYPERLINK]: (node, next) => <a href={node.data.uri} rel="nofollow noopener noreferrer" target="_blank" 
            onClick={() => datalayerLinks(next[0].props?.children ? next[0].props.children : next[0])}>{next}</a>
        }
    }
    const optionsId = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, next) => {
                contTitles++;
                return <h2 id={contTitles}>{next}</h2>
            },
            [BLOCKS.HEADING_3]: (node, next) => {
                contTitles++;
                return <h3 id={contTitles}>{next}</h3>
            },
            [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
                if(node.data.target.fields){
                    const defaultSrc = node.data.target.fields?.file['es-CO'].url || ''
                    const src = node.data.target.fields?.file[locale] && node.data.target.fields?.file[locale].url || defaultSrc
                    const alt = node.data.target.fields?.title[locale] || node.data.target.fields?.title['es-CO'] || ''
                    
                    return (
                        <img 
                            className="f-aticle-content-img"
                            src={src}
                            alt={alt} 
                        />
                    )
                }else{
                    return null
                }
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                const entryInfo = node.data.target.fields;
                const defaultLocale = 'es-CO';

                const getLocalizedField = (field) => field?.[locale] ?? field?.[defaultLocale];

                const cta = getLocalizedField(entryInfo?.cta);
                const imageData = getLocalizedField(entryInfo?.imagen);
                const imageSrc = getLocalizedField(imageData?.fields?.file).url;
                const imageAlt = getLocalizedField(imageData?.fields?.title);

                if (cta && imageSrc && imageAlt) {
                    return (
                        <a
                            href={cta}
                            target={cta.includes('http') ? '_blank' : '_self'}
                            rel={cta.includes('http') ? 'noopener noreferrer' : ''}
                        >
                            <img
                                src={imageSrc}
                                alt={imageAlt}
                                className="f-aticle-content-img"
                            />
                        </a>
                    )
                }
            },
            [INLINES.HYPERLINK]: (node, next) => <a href={node.data.uri} rel="nofollow noopener noreferrer" target="_blank" 
            onClick={() => datalayerLinks(next[0].props?.children ? next[0].props.children : next[0])}>{next}</a>
        }
    }
    

    return (
        <div className="f-article-generic-container">
            {primerosParrafos && documentToReactComponents(primerosParrafos.json, options)}
            { menuAncla && <MenuAnchorArticles menuAncla={menuAncla}/>}
            {documentToReactComponents(contenidoDelArticulo.json, optionsId)}
        </div>
    )
}

export default ArticleGeneric
